::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
::-webkit-scrollbar {
    -ms-overflow-style: none;
}

html, body {
    overflow-y: scroll;
    overflow-x: hidden;
}

body,
p {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.5;
    color: var(--bs-black);
    color: #1a1a1a;
}

.lead {
    font-size: 110% !important;
}

small,
.small {
    font-size: 90%;
    letter-spacing: 0.035ch;
}

.w-90 {
    width: 90vw;
}

.w-95 {
    width: 95vw;
}

.bg-weare {
    width: 400px;
    height: auto;
    opacity: .4;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: var(--bs-gray-dark);
    margin-bottom: 2rem;
}


h2,
h3,
h4,
h5,
h6 {
    line-height: 1.35;
}

h1 {
    font-size: 4rem;
    line-height: 1.2;
}

.h1,
.h1 {
    font-size: 2.8rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3 {
    font-size: 2.4rem;
}

p {
    text-align: left;
}

h2.titolosezione {
    word-spacing: .2rem;
    color: var(--bs-gray-dark);
    text-align: center;
}

.subtitle {
    margin: .5rem auto;
    padding: 5px 20px;
    border-radius: 50%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 1rem;
    font-weight: normal;
    letter-spacing: -0.01em;
    white-space: nowrap;
    background: var(--bs-primary-light);
    color: #0a1f44;
    opacity: 1;
}

.subtitle-white {
    background: #ffffff30;
    color: #ffffff;
    opacity: 1;
}

.bordoalto {
    border-top: 1px solid var(--bs-primary) !important;
}

.bianco-intorno {
    background: #ffffffe2;
    padding: 0.5rem !important
}

.weclass {
    color: var(--bs-tertiary);
    font-size: 112%;
}

.imgThumb img {
    max-height: 100%;
    width: 100%;
}

.titolovalue {
    margin-top: .8rem;
}

.fw-bold {
    font-weight: 700 !important;
}

.fw-semibold {
    font-weight: 500 !important;
}

.accordion .accordion-button,
body,
aside h5 {
    font-size: 1rem !important;
}

p {
    font-size: 1.2rem;
}

aside h5,
aside button p {
    color: #013057 !important;
}

.accordion-faq span.MuiTypography-root {
    background-color: #f8f8f8 !important;
    padding: 0.5rem !important;
}

.MuiContainer-root.headerNsp {
    width: 100% !important;
    max-width: 100% !important;
}

mark {
    background: none;
    position: relative;
    padding: 0;
    color: var(--bs-gray-dark);
}

.markCustom {
    position: absolute;
    bottom: -.1ch;
    left: 0;
    background-repeat: no-repeat;
    -webkit-animation: scale 1.0s forwards;
    /* for less modern browsers */
    animation: scale 1.0s forwards;
    animation-delay: 2s;
    height: 14px;
}

.NSP-dash-modal {
    overflow-x: hidden;
}

@keyframes scale {
    0% {
        width: 0px;
        opacity: 0;
    }

    100% {
        width: 100%;
        opacity: 1;
    }
}


@media (min-width: 1200px) {
    .MuiContainer-root.headerNsp {
        width: 100% !important;
        max-width: 100% !important;
    }

    .lead {
        font-size: 110% !important;
    }
}

.montserrat {
    font-family: 'Montserrat', sans-serif !important;
}

.skew-rotate-z45 {
    transform: rotateZ(45deg);
}

.grayscale-60 {
    filter: grayscale(60%) !important;
    -webkit-filter: grayscale(60%) !important;
    -moz-filter: grayscale(60%) !important;
}

.border-dotted {
    border-style: dotted;
}

.border-dashed {
    border-style: dashed;
}

.bg-ghostwhite {
    background-color: #F5F6FB !important;
}

.bg-black-50 {
    background-color: rgba(0, 0, 0, 0.35) !important;
}

.subHeading {
    font-weight: normal;
    font-size: 1.4rem;
    margin-bottom: 2.3rem;
}

.btn,
.btn-primary {
    font-weight: 700;
    line-height: 1.25;
    color: var(--bs-white);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: var(--bs-primary);
    border: none;
    padding: .6ch 2ch;
    font-size: 1.36rem;
    line-height: 1.3;
    font-weight: bold;
    border-radius: 36rem !important;
    transition: all 0.5s ease-in;
    text-decoration: none;
    text-transform: none;
}

.btn:hover,
.btn-primary:hover {
    color: var(--bs-white);
    background: var(--bs-primary-dark);
    transition: all 0.5s ease-in;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: #58636d;
    background-color: var(--bs-tertiary);
}

.btn-tertiary {
    background-color: var(--bs-tertiary);
    color: var(--bs-white);

}

.btn-tertiary:hover {
    background: var(--bs-tertiary-dark);
}

.btn-outline-primary {
    color: var(--bs-gray-dark) !important;
    border: 3px solid var(--bs-primary) !important;
    background-color: white !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: all 0.5s ease-in;
}

.btn-outline-tertiary {
    color: var(--bs-tertiary);
    border: 3px solid var(--bs-tertiary);
    background-color: white;
    transition: all .5s;
}

.btn-outline-tertiary:hover {
    color: #fff;
    background-color: var(--bs-tertiary);
    border-color: var(--bs-tertiary);
}

.bg-primary-light {
    background: var(--bs-primary-light);
}



.imgNumeri {

    margin: 0 auto 2em auto;
    ;
}

.imgThumb {
    width: 6.875em;
    height: 6.875em;
    position: relative;
}

.imgThumbXs {
    width: 4.875em;
    height: 4.875em;
    position: relative;
}

.imgLarge {
    width: 13em;
    height: auto;
    position: relative;
}

.linea {
    width: 100vw;
}

.w50vw {
    width: 50vw;
}

.imgTessera {
    width: 100%;
    max-width: 20em;
    max-height: 100%;
    text-align: center;
    height: auto;
    position: relative;
}

.tessera-cat {
    min-height: 280px;
}

.img-fluid {
    object-fit: contain;
    max-height: 100%;
}

.img-full {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center center;
}

.tessera {
    display: flex;
    min-height: 210px;
    padding: 40px 25px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-radius: 2em;
    background-color: #ffffff;
    box-shadow: 1px 1px 11px 4px rgb(49 49 49 / 6%);
    box-shadow: 8px 8px 0px 0px var(--bs-primary-light);
    border: 1px solid #dee2e6 !important;
}

.assistenza .tessera:hover {
    box-shadow: 0 0 8px rgb(176, 176, 176);
}

.manoVideo {
    width: 10%;
    height: auto;
}

.manoVideoThumb {
    height: auto;
    width: 20%;
}

.manodx {
    transform: translate(-50%, -50%) scaleX(-1) !important
}

.manosotto {
    transform: translate(-50%, 0) !important;
    width: 5vw;
    height: 2em;
    background: var(--bs-primary);
}

.rigabox {
    margin: 2rem auto 0 auto;
    height: 4px;
    background: var(--bs-tertiary);
    width: 72%;
}

.btn {
    text-transform: none;
}

.pulsanti .btn {
    margin: .5rem;
    font-size: 1.34rem;
}

.pulsanti .btn:first-child {
    margin-left: 0em;
}

.pulsanti .btn:last-child {
    margin-right: 0em;
}



.tessera h4 {
    font-size: 1.2rem;
}

.titolosez {
    text-align: center;
}

.nsp-grayscale {
    filter: brightness(0) saturate(100%) invert(43%) sepia(1%) saturate(0%) hue-rotate(239deg) brightness(103%) contrast(85%) !important;
}

.min-height-50 {
    min-height: 50px !important;
}

.titolosez:before {
    content: "";
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 5px;
    margin-bottom: -0.1em;
    background: var(--bs-tertiary);
    border-top-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
}

.bg-gray {
    background: var(--bs-body-bg);
}

.bg-tertiary-light {
    background: var(--bs-tertiary-light);
}

.imgfeatures {
    margin: 2em;
}

.modal-log-user {
    background: var(--bs-primary-light);
}

.container.bg-primary-light {
    background-color: var(--bs-primary);
}

p.error {
    background: #ff0000;
    padding: .2em .2em;
    line-height: 1.2;
    color: #fff;
}

#categorie_home .container {
    padding: var(--bs-gutter-x);
}

.footer {
    background: #F5F6FB;
    border-top: 1px solid #58636d;
}

.rotate {
    transform: rotate(180deg);
    transform: scaleY(-1);
}

.MuiFormControl-root {
    margin: 0.5em 0 !important;
}

.window-utente {
    max-width: 1200px;
}

.cart .window-utente {
    background: none !important;
    max-width: 1220px;
}

.footer-dark {
    padding: 24 0;
}

.footer-dark a,
.footer-dark a:hover,
.footer-dark p,
.footer-dark,
.footer-dark span {
    color: var(--bs-white) !important;
    opacity: .8;
}

.footer-dark a:hover {
    opacity: 1;
    transition: all .5s;
}

.cart-panel {
    position: relative;
}

span.styles_type__2Teeh {
    border-bottom: 4px solid var(--bs-primary);
}

.text-alert {
    color: var(--bs-color-alert);
}

.header-accordion {
    display: flex;
    align-items: center;
    transition: all .8s;
    opacity: 1 !important;
}

.header-accordion>img:nth-child(1) {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
}

.header-accordion>img:nth-child(2) {
    width: 15px;
    height: 15px;
    margin-right: .3rem;
}

.active .header-accordion {
    padding: 0 1.5rem;
    opacity: 1;
    color: var(--bs-primary);
    text-decoration: underline;
    font-weight: bolder;
}

.active.blocked .header-accordion {
    padding: 0;
    opacity: 1;
}

.blocco-eco {
    flex: 1 1;
    flex-flow: row;
    justify-content: flex-start;
}

.titolo-pratica {
    text-align: left;
    width: 100%;
    font-weight: bold;
}

w-50 {
    width: 50%;
}

.z-index-n1 {
    z-index: -1;
}

.z-index-n2 {
    z-index: -2;
}

.aggiungifisso {
    position: fixed;
    bottom: 5px;
    right: 15px;
    border: 4px solid #fff !important;
    z-index: 999;
}

.tessera-dashboard {
    padding: 0em !important;
    background: var(--bs-white);
    border-radius: 1rem;
    width:100%;
}

.btn {
    font-size: 1.1rem;
}


.fs-md-6 {
    font-size: 1rem !important;
}

.top-lg-5 {
    top: 5% !important;
}

.reflect-x {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.bg-half-primary {
    background: linear-gradient(0deg, var(--bs-primary) 50%, #FFFFFF 50%);
}

#numeri_home,
.bg-half-gray {
    background: linear-gradient(90deg, #F5F6FB 50%, #FFFFFF 50%);
}


.bg-eco {
    background: linear-gradient(90deg, #F5F6FB 50%, var(--bs-primary-light) 50%);
}

.container.bg-primary-light {
    background-color: var(--bs-primary-light);
}

.bg-lg-primary-light {
    background-color: var(--bs-primary-light) !important;
}

.bg-md-gray-100 {
    background-color: var(--bs-gray-100) !important;
}

.md-rotate {
    transform: rotate(180deg);
}

.titolosez {
    text-align: left;
}

.nsp-vstep-header .nsp-vstep-btn {
    width: 50%;
}



.center {
    margin: 0 !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bigger {
    font-size: 110%;
}

.xbigger {
    font-size: 130%;
}

.xxbigger {
    font-size: 150%;
}

.smaller {
    font-size: 90%;
}

.xsmaller {
    font-size: 80%;
}

.xxsmaller {
    font-size: 50%;
}


.bg-gradient-whiteTraffic-bottom-top {
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(246, 246, 246, 1) 30%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(246, 246, 246, 1) 30%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(246, 246, 246, 1) 30%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#F5F6FB", GradientType=1);
}

.rBtn-slide svg,
.lBtn-slide svg {
    fill: var(--bs-primary);
}

.svg-primary {
    filter: brightness(0) saturate(100%) invert(22%) sepia(83%) saturate(2574%) hue-rotate(221deg) brightness(81%) contrast(122%) !important;
}

.bg-half-gray-h {
    background: #fff;
}

.translate-middle-50-x {
    transform: translateX(-50%) !important;
}

.translate-center-custom {
    transform: translateX(-50%) translateY(50%) !important;
}

.desaturate img {
    filter: grayscale(100%);
    max-height: 60px;
    margin: auto;
}

.fs-5 {
    font-size: 1.2rem !important;
}

.no-border {
    border: 0 !important;
}

.text-gray-dark {
    color: var(--bs-gray-dark);
}

.btn-outline-primary:hover,
.btn-outline-primary:hover label {
    color: var(--bs-white) !important;
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
    opacity: 1 !important;
}

.btn-outline-primary label {
    transition: all 0.5s ease-in;
    background-color: none !important;
}

.bg-black-20 {
    background-color: #00000030 !important;
}

.bg-primary-20 {
    background-color: #0524dd30 !important;
}

.bg-white-20 {
    background-color: #ffffff30 !important;
}

.bg-white-60 {
    background-color: #ffffff99 !important;
}

.MuiOutlinedInput-root {
    border-radius: 1rem !important;
}

.swiper-slide:hover .card,
.tessera-cat:hover {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%) inset, 0 2px 4px -1px rgb(0 0 0 / 6%) inset !important;
}



.swiper-slide:hover {
    box-shadow: none;
    transition: all 0.5s ease-in;
    overflow: visible;
}

.tessera .imgThumb,
.card .imgThumb {
    margin: 4% auto !important;
}

.swiper-slide:hover img,
.swiper-slide .tessera:hover .imgThumb {
    filter: grayscale(20%);
    transform: scale(1.1);
    transition: all 0.5s ease-in;
}

.aggiungifisso {
    animation: tilt-shaking 0.25s linear 2;
}

.risultato:hover {
    text-decoration: underline;
    color: var(--bs-primary);
}

.m-top-120px {
    position: absolute;
    top: 100px;
    width: 100%;
    height: 100%;
    background: #fff;
}

.right-circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 1.2rem;
    float: right;
}

.trustpilot-widget iframe {
    max-width: 100%;
}

@media (min-width: 768px) {
    h1 {
        font-size: calc(1.4rem + 1vw);
    }

    .breve_decrizione p {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    w-md-50 {
        width: 50%;
    }

    h3,
    .h3 {
        font-size: calc(1rem + .45vw) !important;
    }

    h3.h5 {
        font-size: 1rem !important;
        text-align: left;
    }

    .lead {
        font-size: 110% !important;
    }

    .subHeading {
        font-size: 1.3rem;
    }

}

.w-100-80 {
    width: calc(100% - 80px);
}

@media (max-width: 767px) {

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        text-align: center;
    }

    h1 {
        font-size: calc(1.8rem + 1.8vw);
    }

    .h1 {
        font-size: calc(1.6rem + 1.5vw);
    }

    h2,
    .h2 {
        font-size: calc(1.5rem + 1.2vw);
    }

    h3,
    .h3 {
        font-size: calc(1.2rem + 1vw);
    }

    h4,
    .h4 {
        font-size: calc(1rem + .8vw);
    }

    h2.titolosezione {
        word-spacing: .05rem;
    }

    .subHeading {
        font-weight: normal;
        font-size: 1.2rem;
        margin-bottom: 2.3rem;
    }

    p {
        font-size: 1rem;
    }

    .lead {
        font-size: 110% !important;
    }

    .footer-dark {
        text-align: center;
        margin-top: 1rem;
    }

    .w-85vw {
        width: 85vw;
    }

    .tessera {
        padding: 20px 12px 12px;
    }

    .contenuto-dashboard {
        min-height: 90vh !important;
        width:100%;
        max-width:100%;
        overflow-x: hidden;
    }

    .desaturate img {
        max-width: 85%;
        text-align: center;
        display: block;
    }

    .container {
        padding-right: 1rem;
        padding-left: 1rem;
    }

}

@media (min-width: 992px) {
    .m-top-120px {
        position: static;
        top: 0;
        width: 100%;
        background: #fff;
    }

    .position-md-fixed {
        position: fixed;
    }

    .col-count-2 {
        column-count: 2;
    }

    .tp-widget-wrapper {
        margin: 0 !important;
    }

    .contenuto-dashboard {
        height: calc(95vh - 180px);
    }

    .position-lg-fixed {
        position: fixed;
    }

    .position-lg-sticky {
        position: sticky;
    }

    .w-dashboard {
        width: calc(100% - 48px);
    }

    .pulsanti {
        max-width: 400px;
    }

    .pulsanti.pulsanti-header {
        max-width: none;
    }

    .translate-middle-md-x {
        transform: translateX(calc(50% - var(--bs-larghezza-cerchio))) !important;
    }

    .position-lg-absolute {
        position: absolute;
    }

    .lg-vh-95 {
        height: 95vh !important;
    }

    .cart-panel {
        flex-flow: column;
    }

    .h-lg-75 {
        height: 75% !important;
    }

    .bg-half-gray-h {
        background: linear-gradient(0deg, var(--bs-body-bg) 50%, #FFFFFF 50%);
    }

    .bg-half-gray-reverse-h {
        background: linear-gradient(0deg, #FFFFFF 50%, var(--bs-body-bg) 50%);
    }

    .lead {
        font-size: 110% !important;
    }
}

.nobordo .NSP-alert {
    border: none;
}

.NSP-alert .readed img {
    filter: brightness(0) saturate(0%) invert(0%) sepia(104%) saturate(0%) hue-rotate(236deg) brightness(20%) contrast(0%);
}

.scrollbar-typeA::-webkit-scrollbar {
    width: 5px;
}

.scrollbar-typeA::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: var(--bs-primary);
}

.scrollbar-tertiary::-webkit-scrollbar-thumb {
    background: var(--bs-tertiary) !important;
}

.scrollbar-typeA::-webkit-scrollbar-track {
    background: "white";
    border-radius: 15px;
}

fieldset {
    border-radius: 15px !important;
}

.fadeup {
    -webkit-animation: fadeUp .5s 1;
    animation: fadeUp .5s 1;
}

.falldown {
    -webkit-animation: fallDown 2.5s infinite;
    animation: fallDown 2.5s infinite;

}

.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-left],
.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-right] {
    display: none !important;
    top: -9999px !important;
    position: absolute !important;
    left: -9999px !important;
}

.documenti{
    display:none;
}

@keyframes fallDown {
    0% {
        transform: translateY(-1%) rotate(-1deg);
    }

    25% {
        transform: translateY(1) rotate(0deg);
    }

    75% {
        transform: translateY(0%) rotate(-2deg);
        ;
    }



    100% {
        transform: translateY(-1%) rotate(-1deg);
    }

}

@keyframes fadeUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
        -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    50% {
        transform: rotate(0eg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.MuiSelect-nativeInput {
    opacity: 1 !important;
    border: 0 !important;
    text-align: center !important;
}

@supports (-webkit-overflow-scrolling: touch) {

    /* CSS specific to iOS devices */
    body {
        width: 100%;
        max-width: 100%;
    }
}